



























import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import EffectivateBillingTabReport from "./EffectivateBillingTabReport.vue";
import EffectivateBillingTabParameters from "./EffectivateBillingTabParameters.vue";
import { Component, Vue } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useOldService } from "@/services";

// Interfaces
import { IResponseGetEffectivateBilling } from "@core/services/interfaces/oldService/IOldService";
import { IParameters } from "@core/services/interfaces/oldService/IOldService";

@Component({
  name: "EffectivateBilling",
  components: {
    EffectivateBillingTabReport,
    EffectivateBillingTabParameters,

    BTab,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
})
export default class EffectivateBilling extends Vue {
  parameters = {} as IParameters;

  // LifeCycle
  created() {
    this.fetchGetEffectivateBilling();
  }

  fetchGetEffectivateBilling() {
    useOldService
      .requestGetEffectivateBilling()
      .then((response: IResponseGetEffectivateBilling) => {
        this.$store.commit(
          "effectivate-billing/SET_MONTHS",
          response.data.MesesList
        );

        this.parameters = response.data.Parametros;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao buscar os parametros e filtros de efetivar faturamento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  setParameters(parameters: IParameters) {
    this.parameters = parameters;
  }
}
