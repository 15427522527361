










































































































































import {
  BModal,
  BForm,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BProgress,
  BProgressBar,
  BFormGroup,
  BFormInput,
  BCardText,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { convertToUSDate } from "@/services/utils";

// Services
import { useOldService } from "@/services";

// Interfaces
import {
  IBodyGeneratePendingToSendCollectionLetter,
  IFile,
  IParameters,
  IResponseUploadFile,
} from "@core/services/interfaces/oldService/IOldService";

@Component({
  name: "ModalGenerateSendCollectionLetter",
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BProgress,
    BProgressBar,
    BFormGroup,
    BFormInput,
    AvButton,
    ValidationProvider,
    ValidationObserver,
    BCardText,
  },
  directives: {
    Ripple,
  },
})
export default class ModalGenerateSendCollectionLetter extends Vue {
  // Props
  @Prop({ default: false }) value!: boolean;
  @Prop({ required: true, default: "" }) year!: string;
  @Prop({ required: true, default: { label: "", value: 0 } }) month!: {
    label: string;
    value: number;
  };
  @Prop({ required: true, default: 0 }) businessToInvoice!: number[];
  @Prop({ required: true, default: 0 }) totalRows!: number;
  @Prop({ required: true }) parameters!: IParameters;

  // Data
  modalVisible: boolean = false;
  $refs = {
    fileInput: {} as HTMLInputElement,
  };
  saving: boolean = false;
  importFile: IFile = {} as IFile;
  uploading = {
    status: false,
    fileName: "",
    progress: 0,
  };

  // Watchs
  @Watch("value")
  showValue() {
    this.modalVisible = this.value;
  }

  // Methods
  handleModalHide() {
    this.modalVisible = false;

    this.$emit("input", this.modalVisible);
  }

  onSubmit() {
    this.saving = true;

    const body: IBodyGeneratePendingToSendCollectionLetter = {
      Ano: this.year,
      DataVencimento: convertToUSDate(this.parameters.DataVencimento),
      Fil_File_Id: 0,
      IdsEmpresasFaturar: this.businessToInvoice,
      Mes: this.month.value,
      ArquivoNumeroReferencia: {
        Fil_File_Id: 0,
        Fil_Nome: this.importFile.name,
        Fil_Guid: this.importFile.displayName,
        Fil_Tamanho: this.importFile.size,
        Fil_TipoArquivo: "",
      },
    };

    useOldService
      .requestGeneratePendingToSendCollectionLetter(body, false)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Carta cobrança enviada com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.modalVisible = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao tentar enviar carta cobrança",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.saving = false;
      });
  }

  openAddFile() {
    const fileInput = this.$refs.fileInput;
    fileInput.dispatchEvent(new MouseEvent("click"));
  }

  handleFileInputChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.uploading.fileName = file.name;
      this.uploading.status = true;
      this.uploading.progress = 0;
      useOldService
        .requestUploadFile(file, {
          onUploadProgress: (progressEvent) => {
            this.uploading.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response: IResponseUploadFile) => {
          this.importFile = response.data.files[0];
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao fazer upload do arquivo",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.$refs.fileInput.value = "";
          this.uploading.fileName = "";
          this.uploading.status = false;
          this.uploading.progress = 0;
        });
    }
  }

  deleteFile(fileName: string) {
    useOldService
      .requestDeleteFile(fileName)
      .then(() => {
        this.importFile = {} as IFile;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Falha ao deletar arquivo",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.$refs.fileInput.value = "";
      });
  }
}
